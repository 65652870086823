<template>
  <div class="content-wrapper">
    <ModalProcessing :showModal="showModal"/>


    <PageHeader screenName="Exportação SMD" :linkItems="linkItems" />

    <main class="card pt-2 px-1 pb-2">
      <validation-observer ref="relatorio_smd_form">
        <b-form>
          <div class="alertaCustomizado rounded" v-if="month != null && month.length > 1" >
            <feather-icon class="iconeAlerta" icon="AlertCircleIcon" size="16"/>
            <span class="alertaCorTextoCustomizada"> 
              Este relatório pode levar em média 1 minuto para ser processado.
            </span>
          </div>
          <span class="d-flex pt-2 pb-2 px-2">
            Para gerar o arquivo que será importado ao SMD, selecione as informações designadas nos campos abaixo e clique em Gerar relatório.
          </span>
          <b-row class="pt-2 px-2">
            <b-col
              lg="5" 
              md="5" 
              sm="12"
            >
              <b-form-group
                label="Selecione o Departamento Regional" 
                label-for="dr" 
                label-class="font_size_label"
              >
                <validation-provider 
                  #default="{ errors }" 
                  name="Departamento Regional" 
                  rules="required"
                >
                  <v-select
                    id="dr"
                    :options="regionalDepartmentOptions"
                    v-model="regionalDepartment"
                    placeholder="Selecione o departamento regional"
                    label="descricao"
                    :clearable="true"
                    item-text="Departamento Regional"
                    item-value="Departamento Regional"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                      {{errors[0]}}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              lg="2" 
              md="2" 
              sm="12"
            >
              <b-form-group
                label="Selecione o ano" 
                label-for="year" 
                label-class="font_size_label"
              >
                <validation-provider 
                  #default="{ errors }" 
                  name="ano" 
                  rules="required"
                >
                  <v-select
                    id="year"
                    :options="years"
                    v-model="year"
                    placeholder="Selecione o ano"
                    label="descricao"
                    item-text="ano"
                    item-value="ano"
                    :clearable="false"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                      {{errors[0]}}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              lg="5" 
              md="5" 
              sm="12"
            >
              <b-form-group
                label="Selecione o mês" 
                label-for="month" 
                label-class="font_size_label"
              >
                <validation-provider 
                  #default="{ errors }" 
                  name="Mês" 
                  rules="required"
                >
                  <v-select
                    id="month"
                    :options="monthOptions"
                    v-model="month"
                    placeholder="Selecione o mês"
                    label="descricao"
                    multiple
                    :selectable="month => month.ativo"
                    item-text="mês"
                    item-value="mês"
                    :clearable="false"
                    @input="changeMonth"
                  >
                    <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>
                  <small v-if="errors[0]" class="text-danger">
                      {{errors[0]}}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          
          <b-row class="pt-1 px-2">
            <b-col
              class="d-flex justify-content-end"
            >
              <b-button
                variant="custom-blue"
                @click.prevent="exportReport"
              >
                <span class="align-middle">Gerar relatório</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </main>
  </div>
</template>
  
<script>
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BSpinner, BFormDatepicker, BFormTextarea, BOverlay } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required } from '@validations'
import vSelect from "vue-select"
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import { actions, subjects } from "@/libs/acl/rules";
import ModalProcessing from '@/views/components/custom/modals/ModalProcessing.vue';

export default {
  title: 'Relatorio SMD',

  components: {
    BForm, BCol, BRow, BFormGroup, BFormInput,
    BButton, BTable, CustomPagination, PageHeader,
    ValidationProvider, BFormDatepicker, vSelect, BFormTextarea,
    ValidationObserver, SucessoModal, ConfirmModal, BSpinner, BOverlay,ModalProcessing,required
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Relatório SMD',
          active: true
        }
      ],
      regionalDepartment: null,
      regionalDepartmentOptions: [],
      years:[],
      year: null,
      monthOptions: [],
      month: [],
      file: {
        name: null,
        blob: null
      },
      showModal: false
    }
  },

  async mounted() {
    if (!this.verifyPermission()) {
      this.$router.push({ name: 'misc-not-authorized' })
    }

    await this.listDepartment();
    await this.listYears();
  },

  methods: {
    async exportReport() {
      const formValidated = await this.validateForm();
      
      if(formValidated) {
        const payload = {
          "id_departamento" : this.regionalDepartment.id,
          "ano" : this.year.toString(),
          "mes": this.month.map(month => month.numeroMes)
        };
        this.showModal = true;
        await this.$http.post(this.$api.reportSmd(), payload, { responseType: 'arraybuffer' }).then((response) => {
          
          const blob = new Blob([response.data], { type: this.month.length > 1 ? 'application/zip' : 'application/txt' });
          this.file.blob = URL.createObjectURL(blob);
          const linkDownload = document.createElement('a');
          linkDownload.href = this.file.blob;
          linkDownload.download = `${response.headers['content-name-file']}`;
          linkDownload.click();
  
          URL.revokeObjectURL(this.file.blob);
        }).catch(() => {
            this.openModalErrorSave();
        })
        this.showModal = false;
        this.clearForm();
      }
    },

    async validateForm() {
      return this.$refs.relatorio_smd_form.validate().then(response => response);
    },

    async listDepartment() {
      await this.$http.get(this.$api.reportSmd()).then(response => {
        response.data.forEach(department => {
          this.regionalDepartmentOptions.push({
            id: department.id_departamento,
            descricao: department.descricao,
          });
        })
      })
    },

    async listMonth() {
      this.month = null;
      this.monthOptions = [];

      const payload = {
        "id_departamento": this.regionalDepartment.id,
        "ano": this.year.toString()
      };

      this.monthOptions.push({
        descricao: 'Todos os meses',
        ativo: true
      });

      await this.$http.post(this.$api.reportSmdMonth(), payload).then(response => {
        response.data.forEach(month => {
          this.monthOptions.push({
            descricao: month.mes,
            ativo: month.ativo,
            numeroMes: month.numeroMes
          });
        })
      })

    },

    changeMonth(months) {
      if(months && this.year) {
        months.forEach(month => {
          const everyMonth = 'Todos os meses';
          if(month.descricao == everyMonth) {
            this.month = this.monthOptions.filter(item => item.descricao != everyMonth && item.ativo);
          }
        })
      }
    },
    
    async listYears() {
      await this.$http.get(this.$api.reportSmdYears()).then(response => {
        response.data.forEach(year => {
          this.years.push(year);
        });
      })
    },

    clearForm() {
      this.regionalDepartment = null;
      this.year = null;
      this.month = null;
      this.monthOptions = [];
      this.file.blob = null;
      this.file.name = null;

      this.$refs.relatorio_smd_form.reset();
    },

    openModalErrorSave() {
      this.$swal({
          title: 'Ocorreu um erro.',
          icon: 'warning',
          iconColor: "red",
          html: '<div>Ocorreu um erro ao tentar gerar o relatório.</div>',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: "btn btn-custom-blue"
          },
          buttonsStyling: false
      });
    },

    verifyPermission() {
      return this.$can(actions.VISUALIZAR, subjects.RELATORIO_SMD_MENU_ROTAS_VISUALIZAR);
    }
  },
  computed: {
    callListMonth() {
      return this.year && this.regionalDepartment;
    }
  },
  watch: {
    async callListMonth(value) {
      if(value) {
        await this.listMonth();
      }
    }
  }
}
</script>
<style scoped>
.hidden_overflow_tabela{
    overflow: hidden;
}

.alertaCustomizado{
  background-color: #FFF3E7;
  padding: 10px;
  display:flex;
  align-items:center;
  margin-bottom: 15px;
  justify-content: center;
}

.alertaCorTextoCustomizada{
  font-size: 14px;
  font-style: "Montserrat";
  font-weight: bold;
  color:#FF7C02;
  text-align: center;
  margin-left: 5px;
}
</style>