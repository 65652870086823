<template>
    <b-modal id="show-aviso" no-close-on-esc no-close-on-backdrop hide-header hide-footer centered v-model="showModal">
      <div class="d-flex flex-column align-items-center justify-center-center my-2 text-center">
        <h2>
            Estamos processando 
            <span class="spinner-border spinner-border-md ml-1" role="status" aria-hidden="true"></span>
        </h2>
      </div>
    </b-modal>
</template>
    
<script>
  import { BModal, BButton, BLink } from 'bootstrap-vue';
  
    export default {
    components: {
      BModal, BButton, BLink
    },
    props: {
      showModal: {
        type: Boolean,
        default: false
      },
  
    },
    methods: {
      confirmAction(modalEvent) {
        this.$emit('confirmed');
        modalEvent.preventDefault();
  
      }
    }
  
  }
  </script>
    